function cropNewsText() {
  const newsTitles = document.getElementsByClassName("news-item__title");
  const newsTexts = document.getElementsByClassName("news-item__text");

  for (let i = 0; i < newsTitles.length; i++) {
    if (newsTitles[i].innerText.length > 90) {
      newsTitles[i].innerText = newsTitles[i].innerText.slice(0, 90) + "...";
    }
    if (newsTexts[i].innerText.length > 90) {
      newsTexts[i].innerText = newsTexts[i].innerText.slice(0, 90) + "...";
    }
  }
}

cropNewsText();

function mainBlockSlider() {
  let position = 0;
  const slides = document.getElementsByClassName("main__item-container");
  const dots = document.getElementsByClassName("main__slider-dots__dot");
  const arrowLeft = document.getElementById("main__slider-arrow-left");
  const arrowRight = document.getElementById("main__slider-arrow-right");

  function updateSlider() {
    for (let i = 0; i < dots.length; i++) {
      dots[i].classList.remove("active");
      dots[i].classList.remove("center");
      dots[i].classList.remove("last");
      dots[i].classList.remove("next");
      slides[i].classList.remove("active");
    }

    dots[Math.abs(position)].classList.add("active");
    dots[Math.abs(position)].classList.add("next");
    if (Math.abs(position) === dots.length - 1) {
      dots[Math.abs(position)].classList.add("last");
    }
    if (Math.abs(position) === dots.length - 2) {
      dots[Math.abs(position)].classList.add("center");
    }

    slides[position].classList.add("active");
  }

  function moveSlides(direction) {
    if (direction === "left") {
      if (position === 0) {
        position = slides.length - 1;
      } else {
        position -= 1;
      }
    } else if (direction === "right") {
      if (position === slides.length - 1) {
        position = 0;
      } else {
        position += 1;
      }
    }
    updateSlider();
  }

  arrowLeft.addEventListener("click", () => {
    moveSlides("left");
  });
  arrowRight.addEventListener("click", () => {
    moveSlides("right");
  });

  setInterval(() => {
    moveSlides("right");
  }, 15000);
}

mainBlockSlider();

function blockSlider(leftArrowId, rightArrowId, containerId, scrollPX) {
  const leftArrow = document.getElementById(leftArrowId);
  const rightArrow = document.getElementById(rightArrowId);
  const container = document.getElementById(containerId);
  let isMouseDown = false;
  let startPosX;
  let startScrollLeft;

  leftArrow.addEventListener("click", () => {
    container.scroll({
      behavior: "smooth",
      left: container.scrollLeft - scrollPX,
      top: 0,
    });
  });

  rightArrow.addEventListener("click", () => {
    container.scroll({
      behavior: "smooth",
      left: container.scrollLeft + scrollPX,
      top: 0,
    });
  });

  container.addEventListener("mousedown", (event) => {
    if (event.button === 0) {
      // Проверяем, что зажата левая кнопка мыши
      container.setAttribute("style", "cursor: grabbing");
      isMouseDown = true;
      startPosX = event.clientX;
      startScrollLeft = container.scrollLeft;
    }
  });

  container.addEventListener("mousemove", (event) => {
    if (isMouseDown) {
      const deltaX = event.clientX - startPosX;
      container.scrollLeft = startScrollLeft - deltaX;
    }
  });

  container.addEventListener("mouseup", () => {
    container.setAttribute("style", "cursor: grab");
    isMouseDown = false;
  });
}

blockSlider(
  "news__btns-arrow-left",
  "news__btns-arrow-right",
  "news__news-container",
  300
);

const sliderBlockInfo = [
  {
    title: "Титан и титановые сплавы",
    text: "ООО «Русатом МеталлТех» предлагает заказчику более 250 номенклатур продукции из титана – слитки, прутки, бесшовные трубы, проволока, плиты, кольца и диски, а также является единственным поставщиком в стране самой широкой размерной линейки титановой продукции от слитков 280 мм до бесшовных титановых труб высокой точности диаметром от 3,0 мм до 325 мм.",
    img: "./img/components-slider-pic.jpg",
    brand1: "",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Кальций и КИП",
    text: "ООО «Русатом МеталлТех» является крупнейшим в мире поставщиком химически чистого металлического кальция по электролитической технологии и кальциевой инжекционной проволоки на основе экструдированного химически чистого электролитического металлического кальция. Продукция на основе электролитического кальция является эталоном качества на российском и международных рынках и применяется в черной и цветной металлургии, фармакологии, медицине,  Кальциевая инжекционная проволока применяется для внепечной обработки стали и используется для изготовления марок стали высокого качества, которые в дальнейшем широко используют в производстве нефте- и газопроводов, а также в автомобилестроении и ",
    img: "./img/components-slider-pic-auto.jpg",
    brand1: "",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Тугоплавкие и редкие металлы",
    text: "ООО «Русатом МеталлТех» поставляет на российский и зарубежные рынки тугоплавкие и редкие металлы: тантал, гафний, ниобий и цирконий. Наличие уникальной производственной базы на АО ЧМЗ позволяет нам являться конкурентоспособным поставщиком продукции из тугоплавких и редких металлов для высокотехнологичных отраслей не только в России, но и за рубежом. Основными потребителями продукции являются компании из авиационно-космической, нефтяной и химической, атомной промышленности и машиностроения.",
    img: "./img/components-slider-pic-pigments.jpg",
    brand1: "",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Редкоземельные металлы и магниты",
    text: "ООО «Русатом МеталлТех» активно развивает направление редкоземельных металлов и магнитов на их основе. Компания реализует проект создания первого в России масштабного производства постоянных редкоземельных магнитов полного цикла. К 2028 году запланирован выход на производство постоянных редкоземельных магнитов на основе отечественного сырья более чем 1000 тонн в год с перспективой расширения до 3000 тонн после 2030 года. Область поставок охватывает различные отрасли промышленности, среди которых автомобилестроение, космос, электронике мощных ветрогенераторов, компьютерная техника и так далее.",
    img: "./img/components-slider-pic-auto.jpg",
    brand1: "",
    brand2: "ООО «Элемаш Магнит»",
  },
  {
    title: "Тонкие и особотонкие провода",
    text: "ООО «Русатом МеталлТех» является единственным в России производителем и поставщиком наноструктурных проводников из медь-ниобиевого сплава CuNb, которые обладают уникальным сочетанием свойств – прочность и электропроводность. Наша кабельно-проводниковая продукция востребована для железнодорожного транспорта, в авиационной и космической технике, в электронике, точном машиностроении, микроэлектронике, робототехнике.",
    img: "./img/components-slider-pic-wires.jpg",
    brand1: "ООО «Русатом МеталлТех»",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Точное литье",
    text: "Поставляем на рынок продукцию, изготовленную методом точного литья, главное достоинство которого – высокая точность передачи размеров изделия и низкая шероховатость поверхности. Производим отливки любых форм весом от нескольких грамм до 40 кг из углеродистых и легированных сталей методом литья по выплавляемым моделям. По чертежам заказчика обеспечиваем полную механическую обработку отливок и отгружаем готовые изделия в соответствии с техническим заданием.",
    img: "./img/components-slider-pic-metal1.jpg",
    brand1: "",
    brand2: "ПАО «НЗХК»",
  },
  {
    title: "Тугоплавкие соединения",
    text: "ООО «Русатом МеталлТех» поставляет химически очищенные тугоплавкие соединения на основе гафния и циркония. Продукция характеризуется высокой температурой плавления, твердостью, упругостью и химической стойкостью, а также металлическими свойствами и способностью к сверхпроводимости. Тугоплавкие соединения широко применяются в инструментальной, атомной, авиационно-космической, нефтяной и химической промышленностях, машиностроении.",
    img: "./img/components-slider-pic-metall.jpg",
    brand1: "",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Керамика",
    text: "Продукция из циркониевой керамики обладает рядом преимуществ, например, коррозионная стойкость, высокая прочность и химическая стабильность. Данные характеристики находят свое применение в таких отраслях как авиастроении, радиоэлектроника, машиностроение, химическая, медицинская и космическая промышленности. В настоящий момент ООО «Русатом МеталлТех»» приступило к разработке технологии получения мелкодисперсных порошков на основе диоксид циркония для протезов тазобедренных суставов, стоматологических дисков и имплантатов.",
    img: "./img/components-slider-pic-ceramics.jpg",
    brand1: "",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Сверхпроводники",
    text: "ООО «Русатом МеталлТех» является единственным поставщиком в России полного цикла сверхпроводников на основе сверхчистого ниобия и его сплавов, которые не уступают, а по некоторым параметрам, а даже превосходят зарубежные аналоги. Сверхпроводники на основе ниобий-титана и ниобий-олово широко используются в медицинской, транспортной, промышленной сферах, а также применяется в сверхпроводящих магнитах как для научных, так и для практических целей.",
    img: "./img/components-slider-pic-cabel.jpg",
    brand1: "ООО «Русатом МеталлТех»",
    brand2: "АО ЧМЗ",
  },
  {
    title: "Нержавеющая сталь",
    text: "ООО «Русатом МеталлТех» является единственным поставщиком на российском рынке тонкостенных холоднокатаных труб повышенной точности круглого сечения из нержавеющих сталей для авиационно-космической отрасли. Также поставляем трубы шестигранного сечения из высокопрочных марок стали по индивидуальным размерам, что позволяет добиться исключительных показателей прочности, надежности и долговечности. Эти трубы применяются в атомной, нефтяной, химической промышленности.",
    img: "./img/components-slider-pic-steel.jpg",
    brand1: "",
    brand2: "ПАО «МСЗ»",
  },
  {
    title: "Никелевые электролитические порошки",
    text: "ООО «Русатом МеталлТех» активно продвигает никелевые электролитические порошки на территории РФ, которые применяются в порошковой металлургии, для устойчивых к коррозии или немагнитных сортов твердых сплавов, в производстве магнитов, аккумуляторов, фильтрующих элементов, электроконтактов, красителей, катализаторов, сварочных электродов, композиционных клеев, ферритов, а также при создании износоустойчивых покрытий на деталях авиационных двигателей.",
    img: "./img/components-slider-pic-powder.jpg",
    brand1: "",
    brand2: "ООО «НПО «Центротех»",
  },
];

function sliderBlockSlider() {
  const img = document.getElementById("slider__picture-img");
  const title = document.getElementById("slider__info-title");
  const text = document.getElementById("slider__info-text");
  const brand1 = document.getElementById("brand1");
  const brand2 = document.getElementById("brand2");
  const titles = document.getElementsByClassName("slider__info-header-item");

  function clearTitles() {
    for (let i = 0; i < titles.length; i++) {
      titles[i].classList.remove("active");
    }
  }

  for (let i = 0; i < titles.length; i++) {
    titles[i].addEventListener("click", () => {
      clearTitles();
      titles[i].classList.add("active");

      img.setAttribute("src", sliderBlockInfo[i].img);
      title.innerText = sliderBlockInfo[i].title;
      text.innerText = sliderBlockInfo[i].text;
      brand1.innerText = sliderBlockInfo[i].brand1;
      brand2.innerText = sliderBlockInfo[i].brand2;
    });
  }
}

sliderBlockSlider();
blockSlider(
  "slider__btns-arrow-left",
  "slider__btns-arrow-right",
  "slider__info-header-items",
  300
);

function mapBlockScale() {
  const mapContainer = document.getElementsByClassName("map__map-container")[0];
  const plusBtn = document.getElementById("map-container__scale-btns-plus");
  const minusBtn = document.getElementById("map-container__scale-btns-minus");
  let zoom = 0.7;

  plusBtn.addEventListener("click", () => {
    if (zoom < 2.1) {
      zoom += 0.2;
      mapContainer.setAttribute("style", `transform: scale(${zoom})`);
    }
  });

  minusBtn.addEventListener("click", () => {
    if (zoom > 0.2) {
      zoom -= 0.2;
      mapContainer.setAttribute("style", `transform: scale(${zoom})`);
    }
  });
}

mapBlockScale();

function mapShowCardsOnClickEvents() {
  const mapItems = document.getElementsByClassName("map-container__item");

  for (let i = 0; i < mapItems.length; i++) {
    mapItems[i].addEventListener("click", () => {
      mapItems[i].classList.toggle("active");
    });
    mapItems[i].addEventListener("mouseleave", () => {
      mapItems[i].classList.remove("active");
    });
  }
}

mapShowCardsOnClickEvents();

blockSlider(
  "activities__btns-arrow-left",
  "activities__btns-arrow-right",
  "activities__activities-container",
  300
);
